(() => {
  const elasticpress = document.querySelector('#input-elasticpress');
  const resultTerm = document.querySelector('#result_term');
  const resultList = document.querySelector('#result_list_container');
  const paginations = document.querySelectorAll('.pagination__items');

  if (!elasticpress) {
    return;
  }

  // Event kaunched when start
  // elasticpress.addEventListener(
  //   'elasticpress.input.moved',
  //   (e) => {
  //     alert('test');
  //   },
  //   false
  // );

  // elasticpress.addEventListener('change', () => {
  //   // empty the the list of all child nodes
  //   while (resultList.firstChild) {
  //     resultList.firstChild.remove();
  //   }
  // });

  window.epAutosuggestItemHTMLFilter = (data, option, i, searchTerm) => {
    // console.log(option);
    // console.log(data);
    // console.log(searchTerm);

    paginations.forEach((pagination) => {
      pagination.innerHTML = '<span aria-is-active="page" class="pagination__item">1</span>';
    });

    resultTerm.innerHTML = `“${searchTerm}”`;

    // empty the the list of all child nodes
    if (i === 0) {
      while (resultList.firstChild) {
        resultList.firstChild.remove();
      }
    }

    // var cat_name = option._source.term_suggest[0];
    // eslint-disable-next-line no-unused-vars
    const content = option._source.custom_filters.chapeau
      ? option._source.custom_filters.chapeau
      : option._source.post_content;

    let contentHtml = ``;

    contentHtml += `<div class="article-item">`;
    contentHtml += `<a class="article-item__title"
               href="${option._source.permalink}"
               title="${option._source.post_name}"
               data-cursor>${option._source.post_title}</a>
            <div class="article-item__desc">`;

    if (option._source.custom_filters.chapeau) {
      contentHtml += `${option._source.custom_filters.chapeau}`;
    }

    contentHtml += `</div>
            <div class="article-item__info">
                <a class="article-item__category"
                   href="{{ post_cat.link }}"
                   title="${option._source.term_suggest[0]}">
                    ${option._source.term_suggest[0]}</a>  par
                <a class="article-item__author" href="${option._source.custom_filters.author_path}">${option._source.post_author.display_name}</a>
            </div>`;

    // `{% if post.version_audio or post.version_audio_manuel is not empty %}
    //     <div class="article-item__podcast d-none d-md-flex">
    //         <div class="article-podcast is-small"
    //              v-scope="ArticlePodcast()"
    //              v-effect="el = $el">
    //             <button class="article-podcast__icon"
    //                     @click="initPlayer()">
    //                 <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                     <circle cx="14.5" cy="14.5" r="14" stroke="currentColor"/>
    //                     <path d="M19.442 14.067a.5.5 0 0 1 0 .866l-7.038 4.064a.5.5 0 0 1-.75-.433v-8.127a.5.5 0 0 1 .75-.433l7.038 4.063z" fill="currentColor" stroke="currentColor"/>
    //                 </svg>
    //             </button>
    //             <div class="article-podcast__text">
    //                 {{ __('ÉCOUTER L’ARTICLE', 'maddyness-front') }}
    //                 {% if post.duration is not empty %}
    //                 <span>({{ post.duration }})</span>
    //                 {% endif %}
    //             </div>
    //             <audio class="article-podcast__player"
    //                    ref="player">
    //                 <source src="{{ post.version_audio_manuel is not empty ? post.get_field('version_audio_manuel').url : post.version_audio }}" type="audio/mp3"/>
    //             </audio>
    //         </div>
    //     </div>
    // {% endif %}`;

    if (option._source.custom_filters.partner) {
      contentHtml += `<div class="article-item__partner">
                En partenariat avec <a href="${option._source.custom_filters.partner.link}"><strong>${option._source.custom_filters.partner.title}</strong></a>
            </div>`;
    }

    contentHtml += `<div class="article-item__date">${option._source.custom_filters.date}</div>`;

    if (option._source.custom_filters.thumbnail.src) {
      contentHtml += `<div class="article-item__media">
                <div class="article-item__image-wrapper">
                    <img
                        src="${option._source.custom_filters.thumbnail.src}"
                        data-src-transform="cover"
                        title="${option._source.custom_filters.thumbnail.title}"
                        alt="${option._source.custom_filters.thumbnail.alt}"
                        class="article-item__image twic"
                    >
                </div>
            </div>`;
    }

    contentHtml += `</div>`;

    resultList.innerHTML += contentHtml;

    // if (data.hits) {
    //   data.hits.hits.forEach((hit) => {
    //     const title = hit._source.post_title;
    //     const modelNumbers = hit._source.meta.model_number;
    //     if (modelNumbers && modelNumbers.length) {
    //       hit._source.post_title = `(${modelNumbers[0].value}) - ${title}`;
    //     }
    //   });
    //   // append `ALL RESULTS` when there is more results than displaying
    //   const total = data.hits.total.value;
    //   if (total > 5) {
    //     const limitedResults = data.hits.hits.slice(0, 5);
    //     const allResultsLink = {
    //       _source: {
    //         post_id: Math.floor(Math.random() * 100000),
    //         permalink: `/?s=${searchTerm}`,
    //         post_title: `Displaying 5 of ${total} results. View all results.`,
    //       },
    //     };
    //     limitedResults.push(allResultsLink);
    //     // reset the array that goes back to EP
    //     data.hits.hits = limitedResults;
    //   }
    // }
    return data;
  };
})();
